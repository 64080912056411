import la from './images/la.jpg';
import logo from './images/SandyspetHotel_LOGO.png';
import dogs from './images/dogs.jpg';
import cats from './images/cats.jpg';
import smallanimal from './images/smallanimal.jpg';
import birds from './images/birds.jpg';
import reptiles from './images/reptiles.jpg';
import fish from './images/fish.jpg';

const images = [
  la,
  logo,
  dogs,
  cats,
  smallanimal,
  birds,
  reptiles,
  fish,
];

export default images;