import React from "react";
import "./style.css";
function Footer() {
  return (
    <footer className="theme-color-dark text-light text-center">
      <span> Copyright 2019. All Rights Reserved.</span>
    </footer>
  );
}

export default Footer;
